// How many words in a game
export const NUM_WORDS = 5;

// Length of each word
export const WORD_LENGTH = 5; // Fixed because of the dictionary

// Data version for the persistent data
export const DATA_VERSION = 18;

// Number of chances (stars) per day
export const CHANCES_PER_DAY = 3;

// Number of hints (word reveals) per day
export const HINTS_PER_DAY = 1;

// Date assumed to be the game for the FIRST puzzle;
export const START_DATE_TIME = Date.UTC(2024, 7 - 1, 14);
