import { useCallback, useMemo, useState } from "react";

import { dateToId, dateToIndex, indexToDate, nowUTC } from "../utils/ArchiveDateUtils";

export type TDate = {
	id: string; // yyyy-mm-dd
	offset: number; // Current offset from "today's game"
	index: number; // Index of currently selected game, starting from 0 on the start date
	realIndex: number; // Real index of today's game
	date: Date; // Actual date for today
	isGameDay: boolean; // Whether it's today's game (if not, it's a historical game)
	goToOffset: (offset: number) => void;
	goToPrev: () => void;
	goToNext: () => void;
};

const useDate = (startOffset: number): TDate => {
	const [offset, setOffset] = useState(startOffset);
	const gameStartDateUTC = useMemo(() => {
		return nowUTC();
	}, []);

	const index = useMemo((): number => {
		return dateToIndex(gameStartDateUTC) + offset;
	}, [gameStartDateUTC, offset]);

	const realIndex = useMemo((): number => {
		return dateToIndex(gameStartDateUTC);
	}, [gameStartDateUTC, offset]);

	const date = useMemo((): Date => {
		return indexToDate(index);
	}, [index]);

	const goToOffset = useCallback((offset: number) => {
		setOffset(offset);
	}, []);

	const goToPrev = useCallback(() => {
		setOffset((value) => value - 1);
	}, []);

	const goToNext = useCallback(() => {
		setOffset((value) => value + 1);
	}, []);

	const state = useMemo((): TDate => {
		return {
			id: dateToId(date),
			offset,
			index,
			realIndex,
			date,
			isGameDay: offset === 0,
			goToOffset,
			goToPrev,
			goToNext,
		};
	}, [offset, index, goToPrev, goToNext]);

	return state;
};

export default useDate;
